.NavigationLink {
  font-size: 16px;
  text-decoration: none;
  color: #718A74;
  align-items: center;
}

.active {
  color: #46604A;
  font-weight: bolder;
}
.logoutBtnDesign {
  background-color: #57775B;
  color: white;
  text-align: center;
  border: none;
  border-radius: 5px;
}
/* This is for menu item */
.pro-menu-item a.active {
  color: var(--main-blue);  /* put any color you want */
  font-weight: 500;
}

/* This is for submenu item */
.nav-member .react-slidedown .pro-menu-item a.active {
  color: #003642;  /* put any color you want */
  font-weight: 500;
}
