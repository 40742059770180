.personalInformation {
  display: flex;
  justify-content: center;
}
.personalInformationLeftSection {
  width: 70%;
}
.personalInformationRightSection {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.InfoTable{
    width: 100%;
}
.InfoTable td{
  border: 1px solid;
  padding: 3px;
}
.bankingInformation {
  margin-top: 50px;
}
.agriculturalActivities {
  margin-top: 50px;
}
.tableHeaderTable{
    width: 100%;
}

.tableHeaderTable td{
    padding: 3px 50px;
    border: solid 1px  black;
}
.tableHeaderTable th{
    padding: 3px 50px;
    border: solid 1px  black;
}
.liveStockInfo{
    margin-top: 50px;
}
.agrigateInitiative{
    margin-top: 50px;
}
.printBtnDesign{
  padding: 10px 20px;
  background-color: #5e8b3c;
  color: white;
}