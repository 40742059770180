.projectInfo {
  display: flex;
  width: 100%;
  height: 520px;
}
.projects {
  width: 50%;
}
.projectMonitoring {
  width: 50%;
}
.inputOutputInfo {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.analyticsSection {
  display: flex;
  width: 100%;
  height: 450px;
}
.farmerAcquisition {
  width: 50%;
  height: 440px;
}
.projectAnalytics {
  width: 50%;
  height: 440px;
}
