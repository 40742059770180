.productSourcingUpdate {
  margin-top: 60px;
}
.wholesalerUpdate {
  margin-top: 50px;
}
.totalDataSection {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  height: 200px;
  grid-column-gap: 20px;
}
.dateWiseDataSection {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  height: 180px;
  grid-column-gap: 20px;
}
.registrationTotalDataSection {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 200px;
  grid-column-gap: 20px;
}
.registrationDateWiseDataSection {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 180px;
  grid-column-gap: 20px;
}

.riceMillUpdate {
  margin-top: 50px;
}
.sourcingTableDataSection{
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-column-gap: 20px;
}
.sourcingTotalDataSection{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  height:'200px'
}