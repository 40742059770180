.sourcing {
  background-color: #ffffff;
  width: 100%;
}
.sourcingContainer {
  background-color: #fbfbfb;
  width: 95%;
  min-height: 100vh;
  margin: 0 auto;
  border-radius: 10px;
}
.sourcingBoxSectionTop {
  display: grid;
  grid-template-columns: repeat(3,1fr);
}
.sourcingBoxSectionBottom {
  display: grid;
  grid-template-columns: repeat(2,1fr);
}
.sourcingCardDesign {
  margin: 2%;
  margin-top: 5%;
  height:450px;
  border-radius: 10px;
  box-shadow:#46604A 1px 3px 5px;
  color: #46604A;
  text-align: center;
}
.sourcingInfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 2%;
}
.sourcingInfoTotalData {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #46604A;
}
.gridView{
  display: flex;
}
.gridViewLeft{
  width: 50%;
}
.gridViewRight{
  width: 50%;
}
.sourcingGridUpper{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
}