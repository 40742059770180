.orderFulfillmentCardDesign {
    margin: 2%;
    height: 400px;
    border-radius: 10px;
    box-shadow:#46604A 1px 3px 5px;
    color: #46604A;
}
.custom-select-option {
    height: 60px; /* Adjust the height to your preference */
    padding: 12px; /* Add padding for better spacing */
  }
  