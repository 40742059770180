.homeDashboard {
  background-color: #ffffff;
  width: 100%;
}

.homeNameSection {
  display: flex;
  align-items: center;
  height: 80px;
  margin-left: 40px;
}

.homeDashboardContainer {
  background-color: #fbfbfb;
  width: 95%;
  min-height: 100vh;
  margin: 0 auto;
}

.miniDashboardSection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
.surveySection {
  height: 400px;
  box-shadow: 0px 1px 5px gray;
  margin: 1%;
}
.farmerSalesSection {
  height: 400px;
  box-shadow: 0px 1px 5px gray;
  margin: 1%;
}
.contractFarmingSection {
  height: 400px;
  box-shadow: 0px 1px 5px gray;
  margin: 1%;
}
.mapSection {
  height: 400px;
  box-shadow: 0px 1px 5px gray;
  margin: 1%;
}
.retailerSalesSection {
  height: 400px;
  box-shadow: 0px 1px 5px gray;
  margin: 1%;
}

.sourcingSection {
  height: 400px;
  box-shadow: 0px 1px 5px gray;
  margin: 1%;
}
.farmerSalesDetails {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 2%;
}
.farmerCropSalesDetails {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #46604A;
}
.farmerLivestockSalesDetails {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #46604A;
}
.farmerTotalSalesDetails {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #46604A;
}
.farmerSalesFilter {
  height: 150px;
}
.farmerCashSalesDashboardBtn {
  text-decoration: none;
  color: #46604A;
}
.farmerCashSalesDashboardBtn:hover {
  color: #46604A;
}

.contractFarmingDetails {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 2%;
}
.totalCreditAmountDetails {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #46604A;
}

.totalRecoveredDetails {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #46604A;
}

.totalPendingDetails {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #46604A;
}

.paidDetails {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #46604A;
}
.unpaidDetails {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #46604A;
}
.contractFarmingFilter {
  height: 150px;
}
.contractFarmingSelectDesign {
  padding: 10px 20px;
  background-color: #5e8b3c;
  color: white;
  margin: 5px;
  text-align-last: left;
  padding-left: 29px;
  font-size: 13px;
}

.sourcingDetails {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 2%;
}
.sourcingSelectDesign{
  padding: 10px 10px;
  background-color: #5e8b3c;
  color: white;
  margin: 5px;
  text-align-last: left;
  font-size: 13px;
}
.multipleSurveyContainer{
  display: flex;
}
.consultationFilterSelectDesign{
  padding: 20px 30px;
  border: none;
  background-color: #5e8b3c;
  color: #ffffff;
}