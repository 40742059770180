.priceAppFilterBoxSection {
  height: 85px;
  /* box-shadow:#46604A 1px 3px 5px; */
  border-bottom: lightgray 1px solid;
  color: #46604A;
  display: flex;
  justify-content: flex-start;
}
.chartTableSection {
  display: grid;
  grid-template-columns: 2.5fr 1.5fr;
  height: 550px;
  margin-bottom: 50px;
}
.priceAppTotalTable {
  margin-top: 80px;
}
.seeMoreButtonDesign{
  border: solid 1px black;
  background-color:unset;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
