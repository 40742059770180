
/* Main Layout */
main {
  display: flex;
}

/* Retailer List Sidebar */
.retailer_list {
  height: 100vh;
  overflow: auto;
  background: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.retailer_list .heading {
  background: #46604A;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.retailer_list .heading h2 {
  margin-top: 10px;
}

select, #phoneSearchInput {
  padding: 8px;
  margin: 8px 0;
  border: 1px solid #46604A;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  width: 40%;
}

#totalRetailersPlaceholder {
  margin-top: 0;
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
}

#totalRetailersCount {
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  margin-left: 5px;
  margin-bottom: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: #9cb39e;
  border-radius: 10px;
}

.retailer_list ul.list {
  list-style-type: none;
}

.retailer_list .retailer-data {
  margin: 1rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 1rem;
}

.retailer_list li:last-child .retailer-data {
  border: none;
}

.retailer_list .retailer-data a {
  text-decoration: none;
  font-size: 16px;
  color: #46604A;
  font-weight: 700;
}

.retailer_list .retailer-data p {
  font-size: 12px;
  color: #404040;
}

/* Leaflet Map Styles */
.leaflet-container {
  height: 100vh;
  width: 100%;
}

.leaflet-popup-content-wrapper {
  padding: 0;
  overflow: hidden;
}

.leaflet-popup-content-wrapper .leaflet-popup-content {
  margin: 0;
}

.leaflet-popup-content-wrapper h5 {
  padding: 16px;
  background: #46604A;
  color: #fff;
  font-size: 16px;
}

.leaflet-popup-content-wrapper p {
  padding: 0 16px;
  font-size: 14px;
  margin-bottom: 10px;
}

/* Cluster Marker Icon Styles */
.cluster-icon {
  background-color: #333;
  height: 2em;
  width: 2em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.2rem;
  box-shadow: 0 0 0px 5px #fff;
}

/* Retailer List Filter Styles */
.filter-container {
  background: #46604A;
  color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-container select,
.filter-container #phoneSearchInput {
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  width: 40%;
  background-color: #fff;
  color: #46604A;
}

.filter-container select:focus,
.filter-container #phoneSearchInput:focus {
  outline: none;
}

.filter-container #phoneSearchInput {
  padding: 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 14px;
  width: 40%;
  background-color: #fff;
  color: #46604A;
}

.filter-container #phoneSearchInput::placeholder {
  color: #ccc;
}

.filter-container #phoneSearchInput:focus {
  outline: none;
}

/* Total Retailers Placeholder */
#totalRetailersPlaceholder {
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
}

/* Retailer List Filter Styles */
.filter-container {
  background: #46604A;
  color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
  align-items: center;
}

.filter-container select,
.filter-container #phoneSearchInput {
  padding: 8px;
  margin-right: 10px;
  border: none; /* Remove border for cleaner look */
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: #fff;
  color: #46604A;
  flex: 1; /* Take up remaining space */
}

.filter-container select:focus,
.filter-container #phoneSearchInput:focus {
  outline: none;
  box-shadow: 0 0 0 2px #46604A; /* Add a subtle focus effect */
}

.filter-container #phoneSearchInput {
  border: none; /* Remove border for cleaner look */
  flex: 1; /* Take up remaining space */
}

.filter-container #phoneSearchInput::placeholder {
  color: #ccc;
}

.filter-container #phoneSearchInput:focus {
  outline: none;
  box-shadow: 0 0 0 2px #46604A; /* Add a subtle focus effect */
}

/* Apply hover effect for better interaction */
.filter-container select:hover,
.filter-container #phoneSearchInput:hover {
  background-color: #f3f3f3;
}
