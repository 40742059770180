.cardDesign {
  margin: 2%;
  height: 270px;
  /* border-radius: 10px; */
  /* box-shadow:#46604A 1px 3px 5px; */
  border: gray 1px solid;
  color: #46604A;
}
.SurveyCardDesignFilter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 2%;
}
.surveyCardInnerBody {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: center;
}
