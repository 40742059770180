.DailyUpdate{
    display: Flex;

}
.DailyUpdateLeftTable{
    width: 48%;
    margin: 2%;
    height: 200px;
    overflow: scroll;
    display: flex;
    flex-direction: row;
}

.DailyUpdateRightTable{
    width: 48%;
    margin: 2%;
    height: 200px;
    overflow: scroll;
}