.employeeList{
    background-color: #ffffff;
    width: 100%;
}
.employeeHeaderSection{
    display: flex;
    align-items: center;
    height: 80px;
    margin-left: 40px;
}
.employeeContainer {
    background-color: #fbfbfb;
    width: 95%;
    min-height: 100vh;
    margin: 0 auto;
  }