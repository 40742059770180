.consultationDashboard {
  background-color: #ffffff;
  width: 100%
}
.dashboardNameSection {
  display: flex;
  align-items: center;
  height: 80px;
  margin-left: 40px;
}

.consultationDashboardContainer {
  background-color: #fbfbfb;
  width: 95%;
  min-height: 100vh;
  margin: 0 auto;
}
.mainView {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}
.tabsAndTable {
  width: 50%;
}
.totalStats {
  width: 50%;
}
.tabs {
  height: 540px;
}
.ticketTable {
  height: 525px;
}
.stats {
  height: 410px;
}
.statsTable {
  height: 320px;
}
.consultationBarChart {
  height: 327px;
  overflow: auto;
}
.mainViewLeft{
  margin-top: 30px;
  width: 100%;
}
/* .mainViewRight{
  margin-top: 30px;
  width: 30%;
} */
.contractGridUpper{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5px;
}
.contractGridLower{
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-column-gap: 5px;
}
.consultationGridLower{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
}
.surveyKYCGrid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
}


.custom-tabs .ant-tabs-ink-bar {
  background-color: #46604A; /* Active tab underline color */
}

.custom-tabs .ant-tabs-tab:hover {
  color: #46604A; /* Tab text color on hover */
}
