.totalBoxSection {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 10px;
  }
.totalTableSection{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 10px;
}
.totalCardDesign {
    margin: 2%;
    margin-top: 1%;
    height: 90px;
    border-radius: 10px;
    box-shadow:#46604A 1px 3px 5px;
    color: #46604A;
}
.cardFilterDesign {
    /* box-shadow:#46604A 1px 3px 5px; */
    border-bottom:lightgray 1px solid;
    color: #46604A;
}
.salesCardDesign {
    margin: 2%;
    height: 530px;
    /* border-radius: 10px; */
    /* box-shadow:#46604A 1px 3px 5px; */
    border:gray 1px solid;
    color: #46604A;
    
}
.timeLineSection{
    height: 450px;
}