.createProfilePage{
  background-color: #ffffff;
    width: 100%;
}
.createProfilePageSection{
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;
    
  
}
.createProfileHeaderSection{
    display: flex;
    align-items: center;
    height: 80px;
    margin-left: 40px;
}

.createProfileContainer {
    background-color: #fbfbfb;
    width: 95%;
    min-height: 100vh;
    margin: 0 auto;
    
  }
