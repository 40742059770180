.loginDisplayMain{
    width: 50%;
    margin: auto;
    margin-top: 100px;
}
.loginDisplay{
    border: solid 1px ;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    border: dashed 1px #c3d26c;
    border-radius: 10px;
}
.leftHandBox{
    padding: 50px;
}
.rightHandBox{
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fdffef;
    border-left: dashed 1px #c3d26c;
}
.loginDisplay input{
    border: none;
    border-radius: 0px;
    border-bottom: 2px solid #c3d26c;
}
.loginDisplay input:focus{
    box-shadow: none;
}

@media only screen and (max-width: 700px) and ( min-width: 200px) {
    .loginDisplayMain{
        margin-top: 20px;
    }
    .loginDisplay{
        grid-template-columns:1fr;
        grid-auto-flow:dense;
    }
    .leftHandBox{
        padding-left:40px;
    }
    .rightHandBox{
        padding: 10px;
    }
}