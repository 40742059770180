.farmerDashboard {
  background-color: #ffffff;
  width: 100%;
}
.dashboardNameSection {
  display: flex;
  align-items: center;
  height: 80px;
  margin-left: 40px;
}
.farmerDashboardContainer {
  background-color: #fbfbfb;
  width: 95%;
  min-height: 100vh;
  margin: 0 auto;
}
.farmerBoxSection {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 10px;
 
}
.farmerCardDesign {
    margin: 2%;
    height: 150px;
    border-radius: 10px;
    box-shadow:#46604A 1px 3px 5px;
    color: #46604A;
}
.actionBtnDesign{
  border:'2px solid black';
  color: #46604A;
  text-decoration: none;
}
