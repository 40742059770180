@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Dosis", sans-serif;
  font-family: "Roboto Condensed", sans-serif;
}
.App{
display: grid;
grid-template-columns:0fr 3fr;
padding-top: 50px;
}